

  /* TestimonialCard.css */
.card {
    position: relative;
    width: 300px;
    height: 270px;
    background: linear-gradient(45deg, #ff7e5f, #feb47b);
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    margin-top: 5vh;
  }
  
  .active{
    transform: scale(1.3);
  }
  
  .card-inner {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .testimonial-text {
    color: #fff;
    font-size: 13px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
  }
  
  .author-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }
  
  .author-details {
    display: flex;
    flex-direction: column;
  }
  
  .author-name {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  
  .author-role {
    color: #eee;
    font-size: 12px;
  }
  

  /* .heading
  {
    text-align: center;
    margin: auto;
  } */

  .swiper_container_2{
    height: 36rem;
  padding: 2rem 0;
  position: relative;
  }