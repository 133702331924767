/* Collage.css */

.collage-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 5vw;
  width: 70vw;
  margin: auto;
}

.photo {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.photo {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.photo:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.photo:hover img {
  transform: scale(1.2);
}


@media (max-width:570px) {
    .collage-container{
        grid-template-columns:none;
    }
}