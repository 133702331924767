.our-abouts {
    padding: 2rem;
  }
  
  .page-title {
    text-align: center;
    font-size: 6rem;
  }

  
  .about-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4rem;
  }
  
  .about-section.reverse {
    flex-direction: row-reverse;
  }
  
  .about-section-image {
    flex: 0 0 50%;
    max-width: 50%;
    overflow: hidden;
  }
  
  .about-section-image img {
    /* width: 100%; */
    width: 90%;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 30px;
    outline: none;
    scale: 0.7;
  }
  
  .about-section-image:hover img {
    transform: scale(0.9);
  }
  
  .about-section-content {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 2rem;
    position: relative;
  }
  
  .about-section-title {
    font-size: 4.5rem;
    margin-bottom: 1rem;
    position: absolute;
  top: -35vh;
  }
  
  .about-section-description {
    color: #555;
    line-height: 1.5;
    font-size: 2.5rem;
    position: absolute;
  top: -25vh;
  }
  
  @media (max-width: 978px) {

    .page-title{
        font-size: 4rem;
    }
    .about-section-title{
        font-size: 3.5rem;
    }
    .about-section {
      flex-direction: column;
    }
    .about-section.reverse {
        flex-direction: column;
      }
    .about-section-image,
    .about-section-content {
      flex: 0 0 100%;
      max-width: 100%;
      outline: none;
    }
    .about-section-title {
        position:initial;
      }
      
      .about-section-description {
       position: initial;
       font-size: 2rem;
      }
  
    .about-section-image img {
      transition: none;
    }
  }
  

#let-s-transform{
    width:80%;
}