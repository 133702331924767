.timetable {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-collapse: collapse;
    width: 80%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    margin: auto;
    
  }
  .timetablex {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-collapse: collapse;
    width: 80%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    margin: auto;
  }
  @media (max-width:570px){
  .timetable{
    grid-template-columns: repeat(1, 1fr);
  }
  .timetablex{
    grid-template-columns: repeat(1, 1fr);
  }
  }
  .header {
    background: linear-gradient(45deg, #2980b9, #6dd5fa);
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  .cell {
    border: 1px solid #ccc;
    padding: 15px;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .row:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .row:hover .cell {
    background-color: #f0f0f0;
    transform: scale(1.03);
  }
  
  .row:hover {
    background-color: #f5f5f5;
  }
  

  .b{
    font-size: 12px;
    font-weight: bold;
  }