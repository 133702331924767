:root {
  background-color: #ffffff;
  --second-background-color: #000d25;
  --text-color: #080a13;
  --secondary-color: #4debd4;
  --primary-color: #007bff;
  --accent-color-1: #00c853;
  --accent-color-2: #ff5722;
  --accent-color-3: #9c27b0;
  --neutral-color-1: #fffff;
  --neutral-color-2: #333333;
  --neutral-color-3: #ffffff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* outline: none; */
  /* overflow: hidden; */
}
html {
  zoom: normal !important;
  caret-color: black;
}

.isb{
cursor: url('./Assets/new.png'),auto;
}

.isactive{
  cursor: pointer ;
}
.temp {
  /* height: 10vh; */
}

.tempx{
  height: 10vh;
}

.blurify {
  background: inherit;
  filter: blur(8px);
}


body{
  overflow-x: hidden;
}