.prospectus-page {
    display: flex;
    justify-content: space-between;
    padding: 10vh;
    align-items: center;
    justify-content: center;

  }
  
  .prospectus-info {
    flex: 1;
    max-width: 600px;
  }
  
  .download-boxes {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
    align-items: center;
   
  }
  
  .prospectus-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    width: 300px;
    padding: 20px;
  }
  
  .prospectus-box:hover {
    transform: scale(1.05);
  }
  
  .prospectus-box img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
  }
  
  .download-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .download-button:hover {
    background-color: #2980b9;
  }
  

  @media (width<570px) {
    .prospectus-page{
      padding: 10px;
    }
  }