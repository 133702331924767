.youtube-video-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust column size as needed */
    gap: 20px; /* Adjust gap between cards */
    width:90vw;
    margin: auto;
  }
  
  .youtube-video-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    transition: box-shadow 0.3s ease;
    width:300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
 
  
  .youtube-video-thumbnail {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
  }
  
  .youtube-video-details {
    padding: 5px;
  }
  
  .youtube-video-details h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
    margin-left: 14px;
  }
  
  .youtube-video-details p {
    margin: 0 0 0 14px;
    font-size: 14px;
    color: #666;
  }

  @media (max-width: 670px) {
    .youtube-video-cards-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap:40px
      }
      
  }
  