
.container_branch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.name_branch {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 4em;
  text-transform: uppercase;
  letter-spacing: 0;
  margin: 0 0 0.5em 0;
}





.name_branch > span {
    display: inline-block;
    color: black;
    filter: drop-shadow(0 0 2px rgba(255, 125, 255, 0.25)) drop-shadow(0 0 4px rgba(255, 125, 255, 0.25)) drop-shadow(0 0 16px rgba(255, 125, 255, 0.25));
    /* animation: shine 4s infinite alternate; */
  }
  

  .container_branch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
 
  .branches-card {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    transition: transform 0.3s ease-in-out;
    text-align: center;
  }
  
  .branch-card {
    margin-bottom: 20px;
  }
  
  .branch-card h3 {
    margin-bottom: 8px;
    color: #333;
  }
  
  .branch-card p {
    color: #666;
    margin-bottom: 12px;
  }
  
  .directions-btn {
    background-color: #4CAF50;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .directions-btn:hover {
    background-color: #45a049;
  }
  
  /* Optional Animations */
  .branches-card:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }

  
  .branch-image {
    width: 100%;
    border-radius: 12px 12px 0 0;
    object-fit: cover;
    object-position: center center;
  }

  .whole_b{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:4vh;
  }

  @media (max-width: 670px) {
    .name_branch {
        font-size: 2em !important;
    }
    .whole_b {
        flex-direction: column;
    }
}
