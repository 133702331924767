/* timeline ka css */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");
*
{
margin: 0;
padding: 0;
box-sizing: border-box;
}

html {
font-family: "Montserrat", sans-serif;
}

.eventContainer {
min-height: 100vh;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
padding: 100px 0;
background-color: var(--background-color);
overflow-x: hidden;
}

.eventTimeLine {
width: 80%;
height: auto;
margin: 0 auto;
position: relative;
}

.eventTimeLine ul {
list-style: none;
}
.motionaldivision{
padding: 20px;                                

background: radial-gradient(93.29% 93.29% at 50% 50%, rgba(77, 235, 212, 0) 30.52%, rgba(255,125,255,0.5) 89.37%);

filter: drop-shadow(0px 16.9315px 8.46576px rgba(0, 0, 0, 0.25));
/* background-color: rgba(255, 255, 255, 0.8);  */
/* background-color: rgba(255, 255, 255, 0.5); */
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15); */
border-radius: 10px;                          
margin-bottom: 20px;     
filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1)); 
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);            
}

.eventTimeLine ul li:last-child {
margin-bottom: 0;

}

.eventTimeLineContent
{
display: flex;
justify-content: space-between;
align-items: center;
}



.eventTimeLineContent h1 {
/* font-weight: 500; */
font-size: 25px;
/* line-height: 30px; */
margin-bottom: 10px;
}

.eventTimeLineContent p {
font-size: 14px;
/* line-height: 30px; */
font-weight: 300;
}

.eventRight
{
padding-right: 15px;
}

.eventLeft
{
padding-left: 15px;
}


.eventTimeLine h1
{
font-size: 20px;
line-height: 1;
}


.eventTimeLineContent .date {
font-size: 12px;
font-weight: 300;
margin-bottom: 10px;
letter-spacing: 2px;
}


.upcomingeventsCircle{
background: radial-gradient(93.29% 93.29% at 50% 50%, rgba(77, 235, 212, 0) 30.52%, rgba(0, 123, 255,0.35) 89.37%);
border-radius: 100%;
width: 280px;
height: 280px;
margin: auto;
display: flex;
text-align: center;
line-height: 2rem;
align-items: center;
justify-content: center;
margin-bottom: 150px;
z-index: 100;
}

@media only screen and (max-width: 949px) {

  /*D Updates*/
  .eventTimeLineContent{
    flex-direction: column;
  }

  .pastEventPicVertical{
    min-height: 50vh;
    margin: 2vh auto;
    border-radius: 20px;

  }
  .pastEventPicHori{
    min-height: 23vh;
    margin: 2vh auto;
    border-radius: 20px;
    /* min-width: 100%; */
  }

}
@media only screen and (min-width: 950px) {
/* changes here */

.eventTimeLine:after{
  content:'';
  position: absolute;
  height:85%;
  left: 50%;
  top:260px;
  width: 3px;
  transform: translateX(-50%);
  background-color:rgba(0, 123, 255,0.35);
  

  z-index: 1;
} 
/* here */

.eventTimeLine ul li {
  width: 50%;
  position: relative;
  margin-bottom: 50px;
}
.eventTimeLine ul li:nth-child(odd) {
  float: left;
  clear: right;
  transform: translateX(-30px);
  border-radius: 20px 20px 20px 20px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) */
}
.eventTimeLine ul li:nth-child(even) {
  float: right;
  clear: left;
  transform: translateX(30px);
  border-radius: 20px 20px 20px 20px;
  /* box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1) */
  
}

.eventTimeLine ul li:nth-child(odd)::before {
  transform: translate(50%, -50%);
  right: -31px;
}

/* here */
/* .eventTimeLine ul li:nth-child(odd)::before {
  transform: translate(50%, -50%);
  right: -30px;
} */



.eventTimeLine ul li:nth-child(even)::before {
  transform: translate(-50%, -50%);
  left: -31px;
}



/* edited  here */
.eventTimeLine ul li::before
{
content:'';
position: absolute;
height: 20px;
width: 20px;
 background:  rgba(0, 123, 255,0.6); 
 /* background: black; */
border: 2px solid rgba(0, 123, 255,0.45);
border-radius: 50%;
top:13vh;
}


.eventTimeLineContent .date {
  position: absolute;
  top: -30px;
}

}