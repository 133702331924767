.faqsection{ 
  min-height: 100vh;
  width: 100%;
  margin: 0 auto; 
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  
  
  .item{
  margin-bottom : 5px;
  padding : 10px 20px;
  }
  
  .title_faq{
  display : flex;
  justify-content: space-between;
  align-items : center; 
  cursor: pointer;
  }
  
  .content_faq{
  max-height: 0;
  overflow: hidden;
  transition :  all 0.5s cubic-bezier(0,1,0,1);
  }
  
  .content_show {
  height : auto;
  max-height: 9999px;
  transition : all 0.5s cubic-bezier(1,0,1,0);
  }
  
  .sect{
    margin-top: 10vh;
    display: flex;
    width: 80%;
  
  }
  
  
  .faqsection{ 
    min-height: 100vh;
    width: 90%;
    margin: 0 auto; 
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .secondarycolor{
  color: rgba(0,0,0,0.7)
  }
  .starter{
    display: flex;
    align-items: center;
    min-height: 20vh;  
    background:radial-gradient(100.18% 488.14% at 50% 50%,rgba(77,235,212,0) 23.23%,rgba(0, 0, 0, 0.37) 85.73%);
    width: 80%;
    border-radius: 10px;
    padding: 5%;
    margin-top: 5vh ;
  }
  
  
  .heading{
    font-size: 3rem;
    margin: 2rem 0rem; 
  }
  
  .faq{
    max-width: 700px;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #fff;
    cursor: pointer;
  }
  .faq_text{
    min-height: 50vh;
    min-width: 28vw;
  }
  
  .faq_text h1{
    margin: 5vh auto;
    /* font-weight: 700; */
    font-size: 2.5rem; 
    line-height: 33%;
  color: rgba(0,0,0,0.7);
  }
  .faq_text p{
    margin: 5vh auto;
  }
  
  .faq_list{
  margin-top: 5vh;
  }
  .question{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .question h3{
    font-size: 1.8rem;
  }
  
  .answer{
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.4s ease;
  }
  
  .answer p{
    padding-top: 1rem;
    line-height : 1.6;
    font-size: 1.4rem;
     
  }
  
  .faq.active .answer{
    max-height : 300px;
    animation: fade 0.5s ease-in-out;
  }
  
  .faq.active svg{
    transform_faq: rotate(180deg);
  }
  
  /* svg{
    transition: transform_faq 0.5s ease-in;
    pointer-events: none;
  } */
  
  
  @keyframes fade {
    from{
        opacity: 0;
        transform_faq: translateY(-10px);
    }
    to{
        opacity: 1;
        transform_faq: translateY(0px);
    }
    
  }

  @media screen and (max-width : 700px)
  {
    .starter h2{
      font-size: 1.9rem;
    }
    .item {
      margin : 0 auto;
      padding: 50px 20px;
  }
    
  
  .sect{
    display: inline-block;  
    margin-top: 5vh;
  }
  .faq_text{
  min-height: auto;
  text-align: center;
  margin-bottom: 9vh;
  
  }
  .faq_text p{
    font-size: 0.8rem;
  }
  .faq_text h1{
    font-size: 2rem;
    padding: 5%;
  }
  .faq_text h2{
    display: none;
    font-size: 1.5rem;
  }
  
  .faq_list{
  margin-top: 2vh;
  }
  
  .faqsection{
  width: 100%;
  }
 

  }




  .contact {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact p {
    font-size: 18px;
    color: #555;
  }
  
  .contactus {
    font-size: 36px;
    color: #2c3e50;
  }
  
  .contact_form_faq {
    margin-top: 20px;
  }
  
  .grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  
  .contact_flexo {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .inputform_faq {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
  }
  
  .inputform:focus {
    border-color: #3498db;
  }
  
  .submitform{
    grid-column: span 2;
    text-align: center;
  }
  
  .inout {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .inout:hover {
    background-color: #007bff;
  }
  
  /* Add any additional styles or adjustments as needed */
  /* Desktop view */
.contact {
  max-width: 80vw;
  margin: 10vh auto;
  border: 2px solid rgba(0,0,0,0.2);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.grid_container {
  grid-template-columns: repeat(2, 1fr);
}

/* Tablet view */
@media screen and (max-width: 768px) {
  .grid_container {
    display: flex;
    flex-direction: column;
  }
}

/* Mobile view */
@media screen and (max-width: 480px) {
  .contact {
    padding: 10px;
  }

  .contactus {
    font-size: 24px;
  }

  .grid_container {
    grid-template-columns: 1fr;
  }

  .inputform_faq,
  .inout {
    width: 100%;
  }
}
