.button_tip{
    margin-top:5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:2vh;
    margin-bottom: 5vh;
}

@media(width<670px){
    .button_tip{
        flex-direction: column;
    }
}
