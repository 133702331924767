.courses-container {
  position: relative;
  /* overflow: hidden; */
}

.img-cont {
  position: relative;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-cont > img {
  /* max-width: 40vw; */
  height: auto;
  display: block;
  transition: transform 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 25px;
}

@media (width<670px) {
  .img-cont > img {
    width: 100%;
  }
}
.img-cont > img:hover {
  transform: scale(1.05);
}

/* Add other styles and animations as needed */
.courses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.img-cont {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.courses-img {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-cont:hover {
  transform: scale(1.05);
}

.courses-info {
  max-width: 100%;
  margin-top: 20px;
}

.exam-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.exam-description {
  color: #555;
  line-height: 1.5;
}

.study-techniques-title {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #333;
}

.study-techniques-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.study-techniques-list li {
  margin-bottom: 5px;
  color: #555;
}

.study-techniques-description {
  color: #555;
  line-height: 1.5;
}

@media screen and (min-width: 900px) {
  .courses-container {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  .courses-info {
    max-width: 44%;
  }
  
}

@media screen and (min-width: 1024px) {
  .courses-container {
    padding: 50px;
  }

  .courses-info {
    max-width: 49vw;
  }
}


.study-techniques-list li {
    margin-bottom: 5px;
    color: #292929;
    transition: background-color 0.3s ease;
    width:40vw;
  }
  
  .study-techniques-list li:hover {
    background-color: rgba(255,125,255,0.25);
    color: black;
    border-radius: 4px;
  }

  .about-section_new {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  
  
  
  .about-section-image_new {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }
  
  .about-section-image_new img {
    width: 100%;
    height: auto;
    border-radius: 25px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    transform: scale(0.8);
  }

  .about-section-image_new>img:hover{
    transform: scale(1.05);
  }
  
  .about-section-content_new {
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  
  .exam-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .about-section-description_new {
    color: #555;
    line-height: 1.5;
    font-size: 2.5rem;
  }
  @media screen and (min-width: 600px) {
    .about-section_new {
      flex-direction: row;
      justify-content: space-between;
    }


  
    .about-section-image_new {
      margin-bottom: 0;
      margin-right: 20px;
    }
  
    .about-section-content_new {
      text-align: left;
    }
 
  }
  
  @media screen and (min-width: 768px) {
    .about-section-image_new {
      max-width: 500px; 
    }
  
    .about-section-content_new {
      max-width: 700px; 
    }
 
  }
  
  @media screen and (min-width: 1024px) {
    .about-section-image_new {
      max-width: 600px; /* Adjust as needed */
    }
  
    .about-section-content_new {
      max-width: 800px; /* Adjust as needed */
    }
    
  }
  

  .reverse{
    flex-direction: row-reverse;
  }


  @media (max-width:670px) {
    .reverse{
        flex-direction: column;
    }
    .about-section-image_new img{
        transform: scale(1.05);
    }
      
  .about-section-description_new  {
    font-size: 1.5rem;
  }
  .study-techniques-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .study-techniques-list li {
    width: 60vw;
    font-size: 1.5rem;
    padding: 20px;
    line-height: 1.5;
  }
  }

  @media (max-width:970px) {
    
      
  .about-section-description_new  {
    font-size: 2rem;
  }
  }