@import url('https://fonts.choosegoogleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.choosecontainer {
  width: 100vw;
  max-height: 100%;
  padding: 0px 8%;
  /* overflow-x: hidden; */
}

.homepage{
  max-width: 100vw;
  overflow-x: hidden;
}
.whyimg{
  width:80%;
  border-radius: 12px;
  object-fit: cover;
  object-position: center center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 28vh;
}
.choosecontainer h1 {
  text-align: center;
  margin-bottom: 60px;
  font-weight: 600;
  position: relative;
}

.choosecontainer h1::after {
  content: '';
  background: var(--primary-color);
  width: 200px;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.chooserow_bene {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 30px;
  place-items: center;
  justify-content: center;
}

.chooseservice {
  text-align: center;
  padding: 25px 10px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  /* background: transparent; */
  /* transition: transform 0.choose5s,back 0.choose5s; */
  /* box-shadow: 0 0 20px rgba(0,0,0,.1); */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.chooseservice i {
  font-size: 40px;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.chooseservice h2 {
  font-weight: 600;
  margin-bottom: 8px;
}

.chooseservice:hover{
  background: var(--primary-color);
  transform: scale(1.choose05);
}

.chooseservice:hover h2{
    color: #fff !important;
}
.chooseservice:hover p{
    color: #fff !important;
}

.chooseservice:hover i {
  color: #fff;
}


.choosebene_desc{
  margin-bottom:35px;
}

.choosebene_end{
  margin-top: 30px;
}