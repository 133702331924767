@keyframes down-btn {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }
  100% {
    opaicty: 1;
  }
}

@-webkit-keyframes down-btn {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }
  100% {
    opaicty: 1;
  }
}

@-moz-keyframes down-btn {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }
  100% {
    opaicty: 1;
  }
}

@-o-keyframes down-btn {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }
  100% {
    opaicty: 1;
  }
}

body {
  margin: 0px;
  /* background-color: #fff; */
}
/* .all_cards{
    background-color: #fff;
} */

.category-name {
  font-family: sans-serif;
  width: -webkit-fill-available;
  text-align: center;
  font-size: 40px;
  margin-top: 123px;
  color: black;
}

.card-category-2 ul,
.card-category-3 ul,
.card-category-4 ul,
.card-category-5 ul .card-category-6 ul {
  padding: 0;
}

.card-category-2 ul li,
.card-category-3 ul li,
.card-category-4 ul li,
.card-category-5 ul li,
.card-category-6 ul li {
  list-style-type: none;
  display: inline-block;
  vertical-align: top;
}

.card-category-2 ul li,
.card-category-3 ul li {
  margin: 10px 50px;
}

.card-category-1,
.card-category-2,
.card-category-3,
.card-category-4,
.card-category-5,
.card-category-6 {
  font-family: sans-serif;
  margin-bottom: 45px;
  text-align: center;
}
.card-category-1 div,
.card-category-2 div {
  display: inline-block;
}

.card-category-1 > div,
.card-category-2 > div:not(:last-child) {
  margin: 10px 5px;
  text-align: left;
}

/* Image Card */
.img-card {
  width: 300px;
  position: relative;
  border-radius: 5px;
  text-align: left;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
}

.img-card .card-image {
  position: relative;
  margin: auto;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
  height: 200px;
}

.img-card .card-image img {
  width: 100%;
  border-radius: 5px 5px 0px 0px;

  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.img-card .card-image:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.img-card .card-text {
  padding: 0 15px 15px;
  line-height: 1.5;
}

.img-card .card-link {
  padding: 0px 15px 30px;
  width: -webkit-fill-available;
}

.img-card .card-link a {
  text-decoration: none;
  position: relative;
  padding: 10px 0;
}
.yahi>img{
  /* width:100%; */
  transform: scale(1.025);
  /* object-fit: contain; */
}

.img-card .card-link a:after {
  top: 40px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 0;
  -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.img-card .card-link a:hover:after {
  width: 100%;
  left: 0;
}

.img-card.iCard-style1 .card-title {
  position: absolute;
  font-family: "Open Sans", sans-serif;
  z-index: 1;
  top: 10px;
  left: 10px;
  font-size: 30px;
  /* color: #fff; */
}

.img-card.iCard-style1 .card-text {
  color: #795548;
  font-size: 20px;
}

.img-card.iCard-style1 .card-link a {
  color: #ff9800;
}

.img-card.iCard-style1 .card-link a:after {
  background: #ff9800;
}

.img-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  /* background: linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.1)); */
}

.img-card:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: inherit;
  filter: blur(10px);
  z-index: -1;
}

.img-card:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.gapi {
  min-height: 40px;
}

.card-text p {
  color: black;
  font-size: 16px;
  line-height: 145%;
}

.read_full {
  font-weight: 350;
  font-size: 18px;
  color: #3e7efe;
}

.read_full:hover {
  color: black;
}


.loop_tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Media query for screens below 430px */
@media (max-width: 430px) {
  .loop_btn {
    margin: 5px; /* Adjust spacing between buttons */
  }
}

/*  */

.loop_btn {
  /* grid-column-gap: 12px; */
  color: #67738c;
  text-align: center;
  letter-spacing: -0.05px;
  background-color: #fff;
  border: 1px solid #c0c8d8;
  border-radius: 10px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  margin-left: 20px;
  /* margin-bottom: 5px; */
  margin-bottom: 30px;
}
.current {
  outline: 2px solid #1f69ff;
}

.hire {
  display: flex;
}
/* .outer_text_home{
    position: absolute;
  left: 27vw; */
/* } */

/* .centerone {
  font-size: 36px;
  width: 70vw;
  margin: auto;
} */

.outer_text_home button {
  margin-top: 30px;
}

@media (max-width: 720px) {
  .loop_btn {
    color: #67738c;
    text-align: center;
    letter-spacing: -0.05px;
    background-color: #fff;
    border: 1px solid #c0c8d8;
    border-radius: 10px;
    padding: 6px 10px;
    font-size: 10px;
    line-height: 11px;
    display: flex;
    margin-left: 3px;
  }
}

.whole_courses{
  border:2px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin:20px 40px
}

@media (max-width:470px) {
  .img-card{
    width:70vw;
  }
  .whole_courses{
    border: none;
  }
  .card-category-2 ul li{
    margin: auto;
  }
}