.festival_container {
    position: absolute;
    top: 11vh;
    left: 50%;
    transform: translateX(-50%);
    background-color:var(--primary-color);
    color: #ffffff; 
    padding: 20px;
    
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 999;
    /* max-width: 300px;  */
    width: 100%;
  }


  
  .festival_container.show {
    opacity: 1;
    animation: slideInUp 0.5s ease-in-out; /* Add a slide-up animation */
  }
  .none{
    display: none!important;
  }
  @keyframes slideInUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  @media (max-width: 600px) {
    .festival_container {
      max-width: 90%;
    }
  }
  

  .custom_css{
    position: absolute;
    top:15vh;
    color: yellow;

  }

  .festival_container p{
    font-size: 2rem;
  }

  @media (max-width:970px) {
    .festival_container{
      display: none;
    }
  }