  .modal {
    position: fixed;
    inset: 0;
    z-index: 1000;
    padding: 20px;
    z-index: 1000;
  }

  .noline {
    text-decoration: none;
  }